@font-face {
  font-family: Ubuntu;
  src: url("./fonts/Ubuntu-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

#contents-wrapper {
  flex: 1 0 auto;
}

body {
  font-family: "Ubuntu";
  color: #004a5c;
}

section::after,
footer::after {
  content: "";
  display: block;
  clear: both;
}

a {
  text-decoration: none;
  vertical-align: middle;
  color: white;
}

a:hover,
#lang-selector select:hover {
  color: #86c9ed;
}

fieldset {
  border-radius: 10px;
}

/* header
======================================== */

#site-header {
  position: relative;
  left: 0;
  right: 0;
  height: 3.5em;
  /* margin: 1em; */
  background-color: #007691;
}

.logo {
  position: absolute;
  width: 5.5em;
  left: 5em;
  margin-top: 1.5em;
  text-align: left;
  z-index: 1;
}

/* Navigation / Navbar
======================================== */
#user-login-logo {
  display: flex;
  height: 100%;
  position: absolute;
  text-align: center;
  margin-left: -2em;
  width: 1.4em;
  z-index: 2;
}
.navbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  height: 100%;
}

.navbar-menu {
  display: flex;
  height: 100%;
  align-items: center;
  list-style: none;
  margin: 0 1em;
}

.navbar-menu li {
  display: flex;
  margin: 0 0.6em;
  align-items: center;
  align-content: center;
}

#lang-selector select {
  background: #007691;
  color: white;
  border: none;
  font-size: 1em;
  margin-left: 0.7em;
  appearance: none;
}

.navbar-login {
  position: relative;
  height: 100%;
  width: 100%;
  text-transform: uppercase;
}

.navbar-login-options {
  display: none;
  padding-top: 0.3em;
  margin-left: -6.3em;
  position: absolute;
  flex-direction: column;
  background: #007691;
  border-radius: 0.5em;
  z-index: 1;
}

.navbar-login:hover #dropdown {
  display: flex;
}

.select-option {
  margin: 0.5em;
  width: 10em;
  font-size: 0.9em;
}

/* navbar logged in
======================================== */
#navbar-logged-in {
  height: 100%;

  display: flex;
  align-items: center;
  list-style: none;
  margin: 1em;
}

#logged-user {
  font-style: italic;
  font-size: 0.8em;
}

/* search
======================================== */
/* .search-container {
  background: url('../images/graduation.jpg');

} */
.search-box-info {
  position: absolute;
  width: 100%;
  text-align: center;
}
.search-desc {
  font-size: 0.9rem;
  margin-top: 1em;
  color: white;
  text-shadow: 0.07em 0.1em #004a5c;
}

.logo-search {
  width: 6em;
  margin-top: 2em;
}

.search-container {
  background-image: url("../images/home/gdir-cover-home.png");
  background-size: cover;
  background-position: center;
  display: flex;
  width: 100%;
  height: 16.5em;
  align-items: flex-end;
  justify-content: center;
}

.search-form {
  display: flex;
  align-self: center;
  padding-top: 1.6em;
  margin-left: 6em;
}

.search-input {
  width: 25em;
  font-size: 0.89rem;
  border-color: #f2f2f2;
  font-weight: bold;
  text-align: center;
}

::placeholder {
  font-weight: normal;
  font-style: italic;
}

.search-input,
.btn {
  border-width: 0.07rem;
  border-style: solid;
  border-radius: 0.5em;
  background-color: white;
  color: #186573;
  padding: 0.3em 1.2em;
}

.search-error {
  position: absolute;
  /* margin-top: -9em; */
  margin-top: -6em;
  color: #e64e49;
  text-shadow: 0.01em 0.02em #004a5c;
  font-size: 0.9em;
  background: white;
  /* border-radius: .5em .5em 0 0; */
  border-radius: 0 0 0.5em 0.5em;
  padding: 0.2em 0.5em;
}

.show-all {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.search {
  display: flex;
  margin: 1em;
}

.search-diploma-field {
  display: flex;
  height: 100%;
  border: 1px solid #004a5c;
  border-radius: 10px;
  padding: 0.3em 1em;
  font-size: 0.5em;
  text-align: center;
}

/* Diploma search
======================================== */
.diploma-search-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.search-gdir-number,
.search-verification {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}

.search-gdir-number,
.search-verification :nth-child(2) {
  border-bottom: 0.1em solid #004a5c;
}

.search-gdir-number,
.search-verification span {
  padding: 0.3em 1.5em;
}

.search-gdir-number :first-child,
.search-verification :first-child {
  margin-bottom: 1em;
}

.search-verification {
  display: flex;
  align-items: center;
}

#gdir-number-bold,
#search-institution {
  font-size: 1.4em;
  font-weight: bold;
}

#search-institution {
  font-size: 1.1em;
}

#search-data {
  padding: 1em;
  font-size: 1.1em;
}

#outer-diploma-div {
  position: relative;
  margin: 3em;

  border: 0.3em solid #004a5c;
}

#inner-diploma-div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  border: 0.1em solid #004a5c;
}

.diploma-image {
  width: 30em;
  height: 22em;
  margin: 0.5em;
}

.status-image {
  width: 8em;
  height: 6em;
}

/* buttons
======================================== */
.btn {
  display: inline-block;
  font-size: 0.7rem;
  text-decoration: none;
  text-transform: uppercase;
  border-color: #004a5c;
  align-self: center;
}

.print-btn {
  height: 3em !important;
}

.btn-search {
  margin-left: 3em;
}

.btn-search:hover,
.btn:hover {
  background-color: #c9e5ea;
}

.btn-search:focus {
  background-color: #004a5c;
  color: #dae5e8;
}

.btn-reg {
  width: 100%;
  padding: 0.8em 0;
  background-color: #007690;
  color: #fafcfc;
  position: absolute;
  display: flex;
  bottom: 0;
  margin-bottom: 10em;
  justify-content: center;
}

.btn-reg:hover {
  background-color: #3fb4ca;
}

.btn-reg:focus {
  background-color: #004a5c;
}

.login-buttons .cancel-button {
  display: none;
}

/* main home section
======================================== */
.gdir-info {
  margin-top: 0.5sem;
  padding: 1.9em 0;
}

.registration-section {
  width: 100%;
  height: 24em;
  display: flex;
  justify-content: center;
  text-align: center;
}

.registration-card {
  position: relative;
  width: 16em;
  margin: 0 3.5em;
}

.home-page-register-img {
  height: 11em;
}

.reg-desc {
  display: table-cell;
  vertical-align: middle;
  padding-bottom: 1.6em;
  height: 6em;
  font-size: 0.85rem;
}

/* login
======================================== */
.login-page,
.about-page,
.faq-page,
.printer-page,
.contact-page,
.terms-page,
.privacy-page,
.diploma-create-page,
.user-home-page,
.edu-home-page,
.details-page,
.edu-verified-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2em;
}

.login-page-cover,
.about-page-cover,
.faq-page-cover,
.printer-page-cover,
.contact-page-cover,
.terms-page-cover,
.privacy-page-cover,
.diploma-create-page-cover,
.user-home-page-cover,
.edu-home-page-cover,
.details-page-cover,
.edu-verified-page-cover {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.login-page-img,
.about-page-img,
.faq-page-img,
.printer-page-img,
.contact-page-img,
.terms-page-img,
.privacy-page-img,
.diploma-create-page-img,
.user-home-page-img,
.edu-home-page-img,
.details-page-img,
.edu-verified-page-img {
  height: 8.25em;
  width: 100%;
}

.cover-bottom-line {
  width: 100%;
  position: absolute;
  align-self: flex-end;
  background-color: white;
  height: 2em;
  background-color: #004a5c;
}

.search-page-section {
  position: relative;
  display: flex;
  flex-direction: column;
}

.cover-bottom-line-search {
  position: absolute;
  align-items: center;
  height: 4em;
  margin-top: 12.5em;
  color: white;
}

.cover-bottom-line-search p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.75em;
  font-style: italic;
}

.title {
  position: absolute;
  color: white;
  width: 90%;
  margin-top: 2em;
  font-weight: bold;
  text-transform: uppercase;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  width: 8em;
  margin: 1em 0;
}

.login-form input {
  padding: 0.2em;
  border: 0.05em solid #004a5c;
  border-radius: 0.5em;
  margin: 0.5em 0;
  height: 2.5em;
  width: 20em;
  text-align: center;
  font-size: 0.4em;
}

.login-buttons {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  margin-top: 0.5em;
}

.login-buttons input {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.4em;
}

.btn-login,
.btn-register,
.btn-submit {
  background-color: #007691;
  color: white;
  width: 15em;
  height: 2.5em;
  margin-bottom: 1.8em;
}

.btn-login:hover,
.btn-register:hover,
.btn-submit:hover {
  background-color: #3fb4ca;
}

.btn-login:focus,
.btn-register:focus,
.btn-submit:focus {
  background-color: #004a5c;
}

.cancel-button {
  text-transform: uppercase;
  font-size: 0.3em;
}

/* registration
======================================== */
.instructions-label,
.required-label {
  font-size: 0.4em;
}

.required-label {
  width: 100%;
  text-align: left;
}

.registration-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2em;
}

.registration-page-cover {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.registration-page-img {
  height: 8.25em;
  width: 100%;
}

.registration-form {
  width: 12em;
  align-self: center;
}

.form-title {
  font-size: 0.5em;
}

.form-fields {
  padding: 0 1em;
  margin: 0 1em;
}

.form-fields div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0.4em 0;
}

.form-fields > div > label {
  align-self: flex-start;
  font-size: 0.35em;
  margin: 0.3em;
}

.form-fields input:not([type="checkbox"]) {
  width: 100%;
  font-size: 0.5em;
  border: none;
  border-bottom: 1px solid #004a5c;
}

.checkbox-block div {
  flex-direction: row;
  margin: 0.1em 0;
}

.checkbox-block label {
  display: inline-flex;
  font-size: 0.35em;
  margin-left: 0.5em;
}

.checkbox-block a {
  color: #004a5c;
  margin-left: 0.3em;
  text-decoration: underline;
}

/* about
======================================== */
.page-content {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 2em auto;
  font-size: 0.5em;
}

.page-content div {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 2em auto;
}

.page-content img {
  width: 20em;
  margin: 1em auto;
}

.page-content span {
  font-size: 1.5em;
  font-weight: bold;
}

.number-example {
  width: 15em;
  align-self: center;
  margin: 1.5em;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
  color: #004a5c;
  background-color: white;
  border: 1px solid #004a5c;
  border-radius: 15px;
}

/* FAQ
======================================== */
.question-box {
  margin: 2em auto;
  width: 70%;
}

.question {
  font-size: 1.2em;
  font-weight: bold;
}

.answer {
  margin: 0.5em;
  font-style: italic;
}

/* contact
======================================== */
.contact-form {
  display: flex;
  flex-direction: column;
  width: 20em;
  margin: 0 auto;
}

.contact-form > input,
.contact-form textarea {
  margin: 0.3em 0;
  font-size: 0.8em;
  padding: 0.5em;
  border: 1px solid #004a5c;
  border-radius: 10px;
}

.contact-form > textarea {
  resize: none;
}

.btn-submit {
  align-self: center;
}

/* profile section
======================================== */
.content-wrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.secondary-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profile-section {
  float: left;
  width: 30%;
  height: 54.4vh;
  border-right: 1px solid #004a5c;
}

.user-profile {
  font-size: 1rem;
  height: 100%;
}

.user-profile img {
  color: #004a5c;
  width: 5rem;
  margin-top: 1em;
}

.user-profile a,
.user-home-page a {
  color: #004a5c;
  font-size: 0.7em;
  margin: 5em 0;
  height: 100%;
}

.user-profile p {
  margin-bottom: 1em;
}

.profile-links {
  display: flex;
  flex-direction: column;
}

.profile-links a {
  margin: 0.5em 0;
}

/* diploma card
======================================== */
.verified-diploma-text {
  text-align: left;
}

#verified-diploma-gdir {
  font-size: 0.7em;
  font-style: italic;
  color: #3e3e3e;
  text-align: right;
}

#verified-wrapper {
  display: flex;
  flex-direction: column;
  width: 14em;
}

.diploma-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 0.5em;
}

.diploma-component > fieldset {
  height: 9.5em;
  font-size: 0.5em;
}

.diploma-section-div {
  display: flex;
  flex-direction: row;
}

.diploma-list {
  display: flex;
  width: 90%;
  padding: 0 0.5em;
}
.diploma-card {
  width: 20em;
  height: 15em;
  font-size: 0.3em;
  padding: 0.5em;
}

.diploma-card-img {
  width: 15em;
  height: 7em;
}

.status-card-img {
  height: 2.5em;
  width: 8em;
}

.no-diplomas {
  display: flex;
  width: 100%;
  height: 10em;
  padding: 1em;
  align-items: center;
  justify-content: center;
}

.diploma-all-link {
  display: flex;
  justify-content: center;
  width: 12em;
}

.diploma-all-link > a {
  align-self: center;
  width: 10em;
  border: 1px solid #004a5c;
  border-radius: 10px;
  padding: 1em;
  margin: 0;
  height: 3em;
}

.diploma-all-link > a:hover {
  background-color: #c9e5ea;
}

/* diploma details
======================================== */
.diploma-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.5em;
  height: 80%;
}

.diploma-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1em;
}

.diploma-details-container > fieldset > legend {
  text-align: left;
  margin-left: 1em;
}

.diploma-details-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-bottom: 0.5em;
}

.diploma-details-img {
  width: 24em;
}

.diploma-details-description > p {
  margin: 1em;
  text-align: left;
}

/* edu diploma
======================================== */
.diploma-wrapper {
  width: 100%;
}

.edu-diploma-wrapper {
  width: 100%;
  height: 100%;
}

.edu-diploma-component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.5em 2.5em;
}

.edu-profile-diploma-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.5em;
}

.last-verified-diplomas > fieldset > legend {
  font-size: 0.5em;
}

.last-verified-diplomas-content {
  width: 25em;
  height: 8em;
  font-size: 0.5em;
  padding: 0.5em;
}

.last-verified-diplomas-content > div {
  font-size: 0.8em;
  margin: 0.5em;
}

.edu-profile-diploma-btns > a,
.qr-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #004a5c;
  margin: 1em;
  width: 10em;
  height: 3em;
}

.pending-diplomas {
  height: 33.5vh;
  border-top: 1px solid #004a5c;
}

.pending-title {
  font-size: 0.5em;
  padding: 0.2em 0;
}

.pending-title > span {
  padding: 0 1.5em;
  padding-bottom: 0.2em;
  border-bottom: 1px solid #004a5c;
}

.diploma-edu-list {
  display: flex;
  flex-direction: column;
  height: 32vh;
  padding: 0.5em;
}

.diploma-edu-card {
  display: flex;
  align-self: center;
  justify-content: space-between;
  align-content: center;
  font-size: 0.6em;
  width: 20em;
  border-bottom: 1px solid #004a5c;
  padding: 0.2em 1em;
  margin: 0.5em;
}

.print-gdir-btn {
  float: left;
  margin-left: 0.5em;
}

.btn-reject {
  margin: 0 1em;
}

#verified-diploma {
  font-size: 0.5em;
  margin-top: 1em;
}

.btn-reject:hover {
  color: white;
  background-color: rgb(255, 109, 104);
}

.btn-approve:hover {
  background-color: aquamarine;
}

/* paginator
======================================== */
#paginator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.7em;
  margin: 0.5em;
}

#paginator button {
  position: relative;
  height: 100%;
  width: 5em;
  font-weight: bold;
  border: 1px solid #004a5c;
  border-radius: 5px;
}

#paginator span {
  margin: 0 0.5em;
}

.btn-prev::before {
  content: "<<";
}

.btn-prev:hover::before {
  content: "prev";
}

.btn-next::before {
  content: ">>";
}

.btn-next:hover::before {
  content: "next";
}

/* footer
======================================== */
footer {
  position: relative;
  width: 100%;
  height: 4.5em;
}

footer,
footer a {
  background: #004a5c;
  color: #fafcfc;
}

footer ul {
  margin: 0 1em;
  list-style: none;
}

footer li {
  display: inline-block;
  margin: 0 0.8em;
}

footer a {
  text-decoration: none;
  text-transform: uppercase;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-main-nav {
  padding: 0.8em 0;
  font-size: 0.85rem;
  text-decoration: underline;
}

.footer-sec-nav a {
  font-size: 0.65rem;
  text-decoration: underline;
}

.trade-mark {
  position: absolute;
  bottom: 0;
  margin-bottom: 0.2em;
  margin-left: 0.3em;
  font-size: 0.6em;
}

/* qr code
======================================== */
.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.qr-wrapper,
#print-wrapper {
  display: flex;
  /* width: 256px; */
  flex-direction: column;
  align-items: center;
  margin: 0.65em 0;
  padding: 0.4em 1em;
}

#hiddenBtn {
  display: none;
}

.qr-text {
  margin-top: 0.3em;
  font-size: 0.7em;
  /* font-size: 0.6em; */
}

.qr-btns > .btn {
  height: 2em;
  font-size: 0.4em;
  margin: 1.5em;
}

.info-text {
  font-size: 0.6em;
}

.info-text > div {
  padding: 0.5em 0;
}
